<template>
  <div id="registrationRules">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 offset-md-1 col-sm-12" style="text-align: justify" v-html="pageContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "RegistrationRules",
    data(){
      return{

      }
    },
    computed:{
      pageContent(){ return this.$store.getters.getRegistrationRules.content;}
    },
    created() {},
    updated() {

    }
  }
</script>

<style scoped>

</style>